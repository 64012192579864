.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #0a0a1a;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.redirect-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  height: 100vh;
  background-color: #f0f0f0;
}

.loading-text {
  width: 40%;
}

.skeleton-text {
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 4px;
  animation: pulse 1.5s infinite;
}

.car-container {
  width: 50%;
  overflow: hidden;
}

.car-skeleton {
  width: 100px;
  height: 50px;
  position: relative;
  animation: moveCar 3s linear infinite;
}

.car-body {
  width: 100%;
  height: 100px;
  background-color: #e0e0e0;
  border-radius: 10px 30px 0 0;
}

.car-wheel {
  width: 20px;
  height: 20px;
  background-color: #c0c0c0;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
}

.car-wheel:first-of-type {
  left: 10px;
}

.car-wheel:last-of-type {
  right: 10px;
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}

@keyframes moveCar {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(300%);
  }
}

.App-link {
  color: #61dafb;
}

.host-padding p {
  margin-top: 2rem;
}

.table-text td {
  font-size: 22px !important;
  padding: 2rem 2rem !important;
}
.table-css {
  border-radius: 20px !important;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
