.custom-header-class {
  margin-left: 30%;
  display: flex;
  gap: 3rem;
  font-size: 18px;
}
.sign-up {
  margin-left: 1rem !important;
}
.custom-head-color {
  background-color: #0a0a1a !important;
  color: white;
}
.heading-contact {
  color: #c4e332 !important;
}

.navbar-toggler {
  background-color: rgb(134, 132, 132) !important;
}

.nav-link {
  color: #fff !important;
}
.i-ride {
  color: #fff !important;
  font-size: 36px !important;
}
.ride-new {
  position: relative;
  right: 4px;
}
.logo {
  width: 20%;
  padding-right: 10px;
  position: relative;
}

a {
  text-decoration: none;
}

@media (max-width: 990px) {
  .custom-header-class {
    margin-left: 0%;
    gap: 0rem;
    font-size: 12px;
  }
  .sign-up {
    margin-left: 0% !important;
  }
}
