.footer-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  justify-content: center;
  align-items: stretch;
  margin-left: 30px;
  width: 100%;
}

.footer-icons {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 176px;
  padding-top: 25px;
  cursor: pointer;
}

.footer-col h5 {
  font-size: 1.1rem;
}

.footer-separate-first-container,
.footer-separate-second-container,
.footer-separate-third-container,
.footer-separate-forth-container {
  text-align: start;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 5px;
}

.footer-heading {
  display: flex;
  align-items: baseline;
  font-weight: 700;
}

.footerLink {
  font-size: 0.9rem;
  cursor: pointer;
}

.footerLink:hover {
  font-size: 1rem;
  color: var(--primary-color);
}

.footer-heading h2 {
  margin-bottom: 20px;
  color: var(--primary-color);
  font-size: 1.8rem;
  font-weight: 700;
}

.footer-heading h2:last-child {
  color: var(--accent-color);
}
.email-icon-text {
  justify-content: space-between;
}

.helping-you-to-get {
  color: #fff;
  font-size: 0.87rem;
}

.footer-separate-second-container h3,
.footer-separate-third-container h3,
.footer-separate-forth-container h3 {
  color: var(--primary-color);
}

.footer-separate-second-container span,
.footer-separate-third-container span,
.footer-separate-forth-container span {
  color: #fff;
}

.footer-icon {
  margin-right: 10px;
}

.heading-clr {
  /* color: #1877f2 !important; */
}
.green-text {
  color: #c4e332 !important;
}
.location-con {
  position: relative;
  top: -1.1rem;
  left: 2px;
}
.appstore-buttons img {
  width: 9rem;
  transition: transform 0.3s ease;
  cursor: pointer;
}
.appstore-buttons img:hover {
  transform: translateY(-5px);
}
.appstore-buttons img:active {
  transform: translateY(5px);
}

@media screen and (max-width: 600px) {
  .footer-container-s {
    position: relative;
    left: -2rem;
  }
  .appstore-buttons img {
    width: 100% !important;
  }
}
