body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "jakarta-normal";
  src: local("Poppins"),
    url(./assets/Fonts/PlusJakartaSans-Regular.ttf) format("opentype");
}

@font-face {
  font-family: "jakarta-semi-bold";
  font-weight: 500;
  src: local("Poppins"),
    url(./assets/Fonts/PlusJakartaSans-SemiBold.ttf) format("opentype");
}

@font-face {
  font-family: "jakarta-bold";
  font-weight: 700;
  src: local("Poppins"),
    url(./assets/Fonts/PlusJakartaSans-Bold.ttf) format("opentype");
}

@font-face {
  font-family: "jakarta-extra-bold";
  font-weight: 800;
  src: local("Poppins"),
    url(./assets/Fonts/PlusJakartaSans-ExtraBold.ttf) format("opentype");
}

@font-face {
  font-family: "jakarta-medium";
  font-weight: 900;
  src: local("Poppins"),
    url(./assets/Fonts/PlusJakartaSans-Medium.ttf) format("opentype");
}
@font-face {
  font-family: "jakarta-light";
  font-weight: 900;
  src: local("Poppins"),
    url(./assets/Fonts/PlusJakartaSans-Light.ttf) format("opentype");
}
*{
  font-family: "jakarta-normal";
}

.bold {
  font-family: "jakarta-bold";
}
.regular {
  font-family: "jakarta-normal";
}
.extra-bold {
  font-family: "jakarta-extra-bold";
}
.font-light {
  font-family: "jakarta-light";
}
.semi-bold {
  font-family: "jakarta-semi-bold";
}
.medium {
  font-family: "jakarta-medium";
}