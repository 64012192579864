.first-container-second-sec {
  width: 44rem;
  height: 35rem;
  animation: translateblue 1s ease-in-out;
}
@keyframes translateblue {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
.rent-your-car {
  margin-top: 11rem;
}

.appstore-button-main img {
  transition: transform 0.3s ease;
  cursor: pointer;
  animation: translateApple 1s ease-in-out;
}
@keyframes translateApple {
  0% {
    opacity: 0;
    transform: translateX(-30px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
.appstore-button-main img:hover {
  transform: translateY(-5px);
}
.appstore-button-main img:active {
  transform: translateY(5px);
}

.ride {
  color: #1877f2;
  font-size: 7rem;
  animation: translateblue 1s ease-in-out;
}
.main-head {
  font-size: 7rem;
  /* font-family: "jakarta-semi-bold"; */
  animation: translateblue 1s ease-in-out;
}
.heading-second {
  font-size: 3.5rem;
  font-family: "jakarta-semi-bold";
  animation: translateblue 1s ease-in-out;
}
.green-line {
  rotate: 90deg;
  color: #c4e332;
  position: relative;
  right: 19.3rem;
  top: 0.3rem;
  animation: translateblue 1s ease-in-out;
}
.desc {
  margin-top: 5px;
  font-size: 1.3rem;
  animation: translateblue 1s ease-in-out;
}
.car {
  position: absolute;
    top: -1rem;
    left: -39px;
    height: 40rem;
    width: 40rem;
  animation: translateXAdd 1s forwards;
}
.landing-image {
  position: absolute;
  top: 0rem;
  left: 0px;
  width: 95%;
  animation: translateXAdd 1s forwards;
  border-radius: 20px;
}
.brands-heading {
  color: #c4e332;
}
@keyframes translateXAdd {
  0% {
    opacity: 0;
    transform: translateX(900px);
  }
  95% {
    opacity: 1;
    transform: translateX(-5px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.search-button {
  width: 177px;
  height: 84px;
  border-radius: 46.5px;
  background: #1877f2;
  position: relative;
  top: -5.2rem;
  right: -37rem;
  border: 8px solid #1e1e1e;
  z-index: 1;
  color: white;
  font-size: 19px;
  animation: translateXsearch 0.8s ease-in-out;
  transition: transform 0.2s ease;
}
.search-button:active {
  transform: translateY(2px);
}
.search-input {
  width: 608px;
  height: 83px;
  border-radius: 51px 0px 0px 51px;
  border: 1px solid rgba(60, 60, 65, 0.24);
  background: #1e1e1e;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  left: 1.2rem;
  padding: 22px;
  margin-top: 4rem;
  color: white;
  animation: translateXsearch 1s ease-in;
}
@keyframes translateXsearch {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  80% {
    transform: translateX(40px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.search-input:focus-visible {
  outline: rgba(92, 92, 97, 0.24) !important;
}
#car-bg {
  position: relative;
}

.slider-container-below {
  height: 30rem;
  padding: 4rem 1rem 5rem 1rem;
}
.slider-images {
  padding: 0px 20px 0px 20px !important;
  margin-left: 5rem !important;
  cursor: pointer;
  transition: transform 0.3s ease;
}
.slider-images:hover {
  transform: translateY(-20px);
  z-index: 9;
  position: relative;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
}
/* .slick-prev,
.slick-next {
  cursor: pointer;
  color: green !important;
  border: none;
  outline: none;
  background: transparent;
} */

#second-car-and-bg {
  position: relative;
}
.v8-car {
  position: absolute;
  top: 6rem;
  left: 70px;
  right: -5rem;
  animation: v8Animation 1s forwards;
}
.honda-car {
    position: absolute;
    top: 0rem;
    left: 50px;
    right: -5rem;
    width: 90%;
    border-radius: 20px;
    animation: v8Animation 1s forwards;
}
.toyota-ai {
  position: absolute;
    top: -7rem;
    left: 20px;
    right: 0rem;
    animation: v8Animation 1s forwards;
    width: 95%;
    border-radius: 20px;
}
@keyframes v8Animation {
  0% {
    opacity: 0;
    transform: translateX(-500px);
  }
  80% {
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.merc {
  position: absolute;
  top: -3rem;
  left: -73px;
  animation: v8Animation 1s forwards;
}
.book-a-car {
  margin-top: 9rem;
}
.car-icon {
  height: 3rem;
  width: 3rem;
  margin-bottom: 1rem;
}
.third-section-second {
  margin-left: 5rem;
  margin-top: 6rem;
}
.to-upgrade {
  color: #c4e332;
  font-size: 30px;
}
.sec-car-desc {
  margin-top: 1rem;
  font-size: 18px;
}
.browse-cars {
  margin-top: 3rem;
  margin-left: 5rem;
  width: 255px;
  height: 80px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #f8f8ff;
  color: #1877f2;
  transition: transform 0.3s ease;
}
.browse-cars:active {
  transform: translateY(5px);
}
.hover-line {
  position: relative;
  top: -5.3rem;
  left: 7.5rem;
}
#arrows {
  position: relative;
}
.left-icon {
  position: absolute;
  right: 0;
  left: 310px;
  top: -7rem;
  cursor: pointer;
  transition: transform 0.3s ease;
}
.left-icon:active {
  transform: translateX(-5px);
}
.right-icon {
  position: absolute;
  right: 0;
  top: -7rem;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.right-icon:active {
  transform: translateX(5px);
}
.container-three-port img:hover {
  transition: transform 0.3s ease;
  transform: translateY(-5px);
}
.solo-car {
  width: 51%;
}
.interirowide {
  width: 100%;
}
.interior,
.onlycar {
  width: 50%;
}
.forth-main-c {
  margin-top: 6rem;
}
.fifth-section {
  margin-top: 6rem;
}
.footer-col {
  gap: 21px;
}

.privacy-policy-container {
  position: relative;
  left: -4rem;
}

.center-privacy {
  margin-left: 3rem;
}
.privacy-policy-size p {
  font-size: 22px;
}
.privacy-policy-size li {
  font-size: 22px;
}
.privacy-policy-size ul li {
  font-size: 22px;
}
.privacy-policy-size ul li {
  font-size: 22px;
}
.font-22 {
  font-size: 22px;
}
.font-32 {
  font-size: 28px !important;
}
.accordion {
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: 20px !important;
  --bs-accordion-inner-border-radius: calc(
    var(--bs-border-radius) - (var(--bs-border-width))
  );
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: rgb(0, 0, 0) !important;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-border-color: #7e7e7e !important;
}
.privacy-policy-container h2 {
  font-family: "jakarta-bold";
}
.accordion-button:focus {
  border-color: none !important;
  outline: 0;
  box-shadow: none !important;
}

.accordion-button::after {
  display: none; /* Hide the default arrow */
}

.accordion-button img {
  width: 20px;
  height: 20px;
}
.privacy-policy-container h3 {
  font-family: "jakarta-semi-bold";
}

.form-container {
  height: 20%;
  margin-bottom: 5rem;
  margin-top: 6rem;
  border: 6px solid #1877f2;
  padding: 10rem 25rem 10rem 25rem;
  border-radius: 30px 30px 0px 30px;
}
.delete-text {
  position: relative;
  top: 10rem;
  left: -18rem;
}

.email-text-t {
  margin-top: 5px;
  font-size: 20px;
}
.terms-conditions p {
  font-size: 22px;
}
.terms-conditions {
  margin-left: 20px !important;
  margin-right: 20px !important;
}
.second-section-landing {
  margin-top: 5rem;
}
@media screen and (max-width: 700px) {
  .rent-your-car {
    margin-top: 0rem !important;
  }

  .heading-second {
    font-size: 2.6rem !important;
  }

  .search-input {
    display: none;
  }
  .desc {
    font-size: 1.1rem !important;
  }
  .search-button {
    display: none;
  }
  .privacy-policy-container {
    position: relative;
    left: 0rem !important;
  }
  .center-privacy {
    margin-left: 0px !important;
  }

  .form-container {
    height: auto !important;
    margin-bottom: 5rem;
    margin-top: 6rem;
    border: 6px solid #1877f2;
    padding: 4rem 1rem 4rem 1rem !important;
    border-radius: 30px 30px 0px 30px;
  }
  .delete-text {
    position: relative;
    top: 3rem !important;
    left: 0rem !important;
    font-size: 15px;
  }
  .second-section-landing {
    /* margin-top: 2rem !important; */
  }
  .first-container-second-sec {
  }
  #car-bg {
    overflow: hidden;
  }

  .car {
    position: absolute;
    top: 6rem;
    left: -28px !important;
    height: auto !important;
    width: 116% !important;
  }
  .first-container-second-sec {
    width: 100% !important;
    height: 21rem !important;
  }
  .slider-container-below img {
    height: 7rem;
    width: 8rem;
  }

  .slider-container-below {
    height: 28rem;
    padding: 4rem 1rem 5rem 1rem;
  }
  .slider-images {
    padding: 0px 20px 0px 20px !important;
    margin-left: 0rem !important;
    cursor: pointer;
    transition: transform 0.3s ease;
  }

  .blue-bg-two {
    position: relative;
    height: 23rem !important;
    width: 100% !important;
    top: -10rem !important;
  }
  .main-first-container {
    flex-wrap: wrap;
  }
  .v8-car {
    top: -6rem !important;
    left: 10px !important;
    height: 13rem !important;
    width: 100% !important;
  }

  .third-section-second {
    margin-left: 0rem !important;
    margin-top: 2rem !important;
  }
  .blue-bg-four {
    height: 20rem;
    top: -9rem;
    width: 100%;
    position: relative;
  }
  .merc {
    height: 19rem !important;
    width: 100% !important;
    top: -9rem !important;
    left: -1rem !important;
  }
  .book-a-car {
    margin-top: 0rem !important;
  }
  .browse-cars {
    margin-left: 0rem !important;
    width: 100%;
  }
  .appstore-button-main img {
    width: 100%;
    margin-bottom: 2rem;
  }
}

@media screen and (min-width: 701px) and (max-width: 1025px) {
  .rent-your-car {
    margin-top: 5rem !important;
  }

  .heading-second {
    font-size: 2.6rem !important;
  }

  .search-input {
    display: none;
  }
  .desc {
    font-size: 1.1rem !important;
  }
  .search-button {
    display: none;
  }
  .privacy-policy-container {
    position: relative;
    left: 0rem !important;
  }
  .center-privacy {
    margin-left: 0px !important;
  }

  .form-container {
    height: auto !important;
    margin-bottom: 5rem;
    margin-top: 6rem;
    border: 6px solid #1877f2;
    padding: 4rem 1rem 4rem 1rem !important;
    border-radius: 30px 30px 0px 30px;
  }
  .delete-text {
    position: relative;
    top: 3rem !important;
    left: 0rem !important;
    font-size: 15px;
  }
  .second-section-landing {
    /* margin-top: 2rem !important; */
  }
  .first-container-second-sec {
  }
  #car-bg {
    overflow: hidden;
  }

  .car {
    position: absolute;
    top: 6rem;
    left: -28px !important;
    height: auto !important;
    width: 116% !important;
  }
  .first-container-second-sec {
    width: 100% !important;
    height: 21rem !important;
  }
  .slider-container-below img {
    height: 7rem;
    width: 8rem;
  }

  .slider-container-below {
    height: 28rem;
    padding: 4rem 1rem 5rem 1rem;
  }
  .slider-images {
    padding: 0px 20px 0px 20px !important;
    margin-left: 3rem !important;
    cursor: pointer;
    transition: transform 0.3s ease;
  }

  .blue-bg-two {
    position: relative;
    height: 23rem !important;
    width: 100% !important;
    top: 1rem !important;
  }
  .main-first-container {
    flex-wrap: nowrap;
  }
  .v8-car {
    top: 6rem !important;
    left: 10px !important;
    height: 13rem !important;
    width: 100% !important;
  }

  .third-section-second {
    margin-left: 3rem !important;
    margin-top: 2rem !important;
  }
  .blue-bg-four {
    height: 20rem;
    top: -5rem;
    width: 100%;
    position: relative;
  }
  .merc {
    height: 19rem !important;
    width: 100% !important;
    top: -5rem !important;
    left: -1rem !important;
  }
  .book-a-car {
    margin-top: 0rem !important;
  }
  .browse-cars {
    margin-left: 0rem !important;
    width: 100%;
  }
  .appstore-button-main img {
    width: 100%;
    margin-bottom: 2rem;
  }
}

.animated-section {
  opacity: 0;
  transform: translateX(250px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.animated-section.in-view {
  opacity: 1;
  transform: translateX(0);
}
